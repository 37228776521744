import {
  Breakpoint,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'

import React, {
  ReactNode,
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
} from 'react'
import { AppDialogCallToAction } from './AppDialogContext'

const DEFAULT_CALL_TO_ACTION: AppDialogCallToAction = {
  text: 'Save',
  proceedHandler: () => undefined,
  colour: 'info',
}

export type BreakpointOptions = Breakpoint | false | undefined

export interface AppDialogOpenOptions {
  maxWidth?: BreakpointOptions
  title?: string
  content: ReactNode
  actions?: AppDialogActions
  removeDismiss?: boolean
}

export interface AppDialogActions {
  dismissText?: string
  callToAction?: AppDialogCallToAction
}

export interface AppDialogRef {
  open: ({ maxWidth, content, title, actions }: AppDialogOpenOptions) => void
  isDisabled: boolean
  setIsDisabled: (newValue: boolean) => void
  callToAction: AppDialogCallToAction
  setCallToAction: (newValue: AppDialogCallToAction) => void
  handleClose: () => void
}

/**
 * The dialog UI that can be opened from anywhere in the app, with the title
 * and content provided by the caller.
 *
 * Note: isDisabled and callToAction can be manipulated anywhere and at any time. So it is important that only
 * one dialog is in use at a time so other calls to setIsDisabled/setCallToAction only effect the open dialog
 */
const AppDialog = forwardRef((_, dialogRef) => {
  const [maxWidth, setMaxWidth] = useState<BreakpointOptions>()
  const [title, setTitle] = useState('')
  const [content, setContent] = useState<ReactNode>(null)
  const [actions, setActions] = useState<AppDialogActions | undefined | null>(
    null
  )
  const [removeDismiss, setRemoveDimsiss] = useState<boolean>(false)

  const [isDisabled, setIsDisabled] = useState<boolean>(false)
  const [callToAction, setCallToAction] = useState<AppDialogCallToAction>(
    DEFAULT_CALL_TO_ACTION
  )
  const [loading, setLoading] = useState<boolean>(false)

  // We enhance the supplied ref here with a method to open the dialog in
  // a way that is scoped to this component.
  //
  // The intention is that the ref is supplied by the AppDialogProvider
  // which renders this component as well as the rest of the app. When
  // the useAppDialog() hook is called within the rest of the app we
  // don't change anything in the AppDialogProvider which would otherwise
  // trigger a re-render of the entire app (a performance killer).
  useImperativeHandle(dialogRef, () => ({
    open: ({
      maxWidth = 'md',
      title = '',
      content,
      actions,
      removeDismiss = false,
    }: AppDialogOpenOptions) => {
      setMaxWidth(maxWidth)
      setTitle(title)
      setContent(content)
      setActions(actions)
      setRemoveDimsiss(removeDismiss)
    },
    isDisabled,
    setIsDisabled,
    callToAction,
    setCallToAction,
    handleClose,
  }))

  /**
   * Closes dialog when the URL/route changes
   */

  /**
   * Updates the dialog's actions, callToAction button when it is set via the hook
   */
  useEffect(() => {
    setActions({
      ...actions,
      callToAction: callToAction,
    })
  }, [callToAction])

  return (
    <Dialog
      open={content !== null}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={maxWidth}
      scroll="paper"
      sx={{
        '& .MuiDialog-container': {
          alignItems: 'flex-start',
        },
      }}
      PaperProps={{
        sx: {
          mt: 8,
        },
      }}
    >
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>{content}</DialogContent>
      {actions && (
        <DialogActions>
          {!removeDismiss && (
            <LoadingButton loading={loading} onClick={handleClose}>
              {actions.dismissText ?? 'Cancel'}
            </LoadingButton>
          )}
          {actions.callToAction && (
            <LoadingButton
              loading={loading}
              variant="contained"
              onClick={() => handleProceed(actions.callToAction.proceedHandler)}
              color={actions.callToAction.colour ?? 'info'}
              disabled={isDisabled}
            >
              {actions.callToAction.text}
            </LoadingButton>
          )}
        </DialogActions>
      )}
    </Dialog>
  )

  /**
   * Handles closing the dialog
   */
  function handleClose() {
    setContent(null)
    setIsDisabled(false)
  }

  /**
   * Handles closing the dialog after the call to action button has been pressed
   * @param proceedHandler
   */
  async function handleProceed(proceedHandler: () => void) {
    try {
      setLoading(true)
      await proceedHandler()
    } finally {
      setLoading(false)
      handleClose()
    }
  }
})
AppDialog.displayName = 'AppDialog'

export default AppDialog
