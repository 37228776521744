import React, { Suspense } from 'react'
import { Await, useLoaderData } from 'react-router-dom'

import FullPageLoader from './FullPageLoader'
import FullPageError from './FullPageError'

/**
 * Component that will ensure any wrapped children are rendered only after the
 * data required for the page has been loaded (based on React Router config),
 * a loading page is displayed in the interim.
 * @param props
 * @returns
 */
const PageLoadingWrapper = (props: React.PropsWithChildren) => {
  // This is the data that is returned by the React Router `loader` property.
  // It's something that needs to be have been wrapped in the react-router
  // `defer` function so that it is compatible with the `Await` component
  // we wrap the child component in. The `Await` component is responsible for
  // rendering the fallback loader up until the data is available - i.e.
  // when the promises deferred are all resolved.
  //
  // Note we're ignoring the type here as it's not possible to know what the
  // defered loader data is going to be now - see https://github.com/remix-run/react-router/discussions/9792
  // for discussion about how to type this safely.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { data }: any = useLoaderData()

  return (
    <>
      <Suspense fallback={<FullPageLoader />}>
        <Await resolve={data} errorElement={<FullPageError />}>
          {props.children}
        </Await>
      </Suspense>
    </>
  )
}

export default PageLoadingWrapper
