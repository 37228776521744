import React from 'react'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'

import { alpha, Collapse, Divider, List, styled } from '@mui/material'
import { NavLink, To, useLocation } from 'react-router-dom'

import appRoutes, {
  AppRouteDefinition,
  AppRouteType,
} from '../routes/AppRoutes'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import theme from 'src/theme'

// Convert the React Router routes configuration into an array of arrays, each
// array represents a section of the menu.
let currentSection = 'main'
const menus = appRoutes
  .filter((route) => Object.keys(route).includes('handle'))
  .reduce<AppRouteDefinition[][]>(
    (result, route) => {
      if (route.handle.menu.section !== currentSection) {
        currentSection = route.handle.menu.section
        result.push([])
      }
      result[result.length - 1].push(route)
      return result
    },
    [[]]
  )

const StyledNavLink = styled(NavLink)(({ theme }) => ({
  color: 'inherit',
  textDecoration: 'none',
}))

/**
 * A styled menu entry component that works with React Router and puts an icon
 * next to the text.
 */
interface MenuEntryProps {
  text: string
  active: boolean
  to: To
  Icon?: any
  key?: string
}
const MenuEntry = ({ to, Icon, text, active }: MenuEntryProps) => (
  <StyledNavLink to={to}>
    <ListItemButton
      sx={{
        borderRight: active
          ? `2px solid ${alpha(theme.palette.primary.main, 0.8)}`
          : 'none',
        backgroundColor: active
          ? alpha(theme.palette.primary.main, 0.2)
          : 'inherit',
      }}
    >
      <ListItemIcon sx={{ minWidth: '40px' }}>{Icon}</ListItemIcon>
      <ListItemText
        primary={text}
        sx={{
          '& .MuiTypography-root': {
            fontSize: '14px',
          },
        }}
      />
    </ListItemButton>
  </StyledNavLink>
)

/**
 * A menu group entry that can be expanded to show child menu items. The menu
 * group itself is not expected to be a navigable route.
 */
interface MenuGroupEntryProps {
  Icon?: any
  text: string
  key?: string
  children?: AppRouteDefinition[]
  groupKey: string
}
const MenuGroupEntry = ({
  children,
  groupKey,
  Icon,
  text,
}: MenuGroupEntryProps) => {
  const currentLocation = useLocation()
  const childRouteActive = children?.some(
    (route: AppRouteDefinition) => route.path === currentLocation.pathname
  )

  const [open, setOpen] = React.useState(childRouteActive)

  return (
    <React.Fragment key={groupKey}>
      <ListItemButton onClick={() => setOpen(!open)}>
        <ListItemIcon sx={{ minWidth: '40px' }}>{Icon}</ListItemIcon>
        <ListItemText
          primary={text}
          sx={{
            '& .MuiTypography-root': {
              fontSize: '14px',
            },
          }}
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {children &&
            children.map((route) => (
              <MenuEntry
                key={route.path}
                to={route.path}
                text={route.handle.menu.text}
                active={route.path === location.pathname}
              />
            ))}
        </List>
      </Collapse>
    </React.Fragment>
  )
}

/**
 * Renders a section of the menu, along with any divider that should be shown.
 */
interface MenuSectionProps {
  sectionKey: string
  menu: AppRouteDefinition[]
  divider: boolean
}
const MenuSection = ({ sectionKey, menu, divider }: MenuSectionProps) => {
  return (
    <React.Fragment key={sectionKey}>
      {divider && <Divider sx={{ my: 1 }} />}
      {menu.map((route, idx) => {
        return route.handle.type === AppRouteType.GROUP ? (
          <MenuGroupEntry
            key={idx.toString()}
            groupKey={idx.toString()}
            Icon={route.handle.menu.icon}
            text={route.handle.menu.text}
          >
            {route.children}
          </MenuGroupEntry>
        ) : (
          <MenuEntry
            key={route.path}
            to={route.path}
            Icon={route.handle.menu.icon}
            text={route.handle.menu.text}
            active={route.path === location.pathname}
          />
        )
      })}
    </React.Fragment>
  )
}

/**
 * The main left hand menu of the application, generated automatically from the
 * routes configuration and the metadata for each route.
 * @returns
 */
const AppMenu = () => (
  <List component="nav">
    {menus.map((menu, idx) => (
      <MenuSection
        key={idx}
        sectionKey={idx.toString()}
        menu={menu}
        divider={!!idx}
      />
    ))}

    <Divider sx={{ my: 1 }} />
  </List>
)

export default AppMenu
