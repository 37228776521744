import { create } from 'zustand'

import AdminApi from '../../api/adminApi'
import { AuthConfidentialClient, LoadedCompany, AuthScope } from './types'
import { ConnectionFormRequest } from './Connections/addConnectionTypes'

export interface CompanyStore {
  company: LoadedCompany
  apiClients: AuthConfidentialClient[]
  availableScopes: AuthScope[]

  /**
   * Get Loaded company
   * @param id Loaded company identifier
   */
  getCompany: (id: string) => Promise<void>

  /**
   * Get Loaded company API clients
   * @param id Loaded company identifier
   */
  getApiClients: (id: string) => Promise<void>

  /**
   * Get available permission scopes
   */
  getAvailableScopes: () => Promise<void>

  /**
   * Add a Loaded company API clients
   */
  addApiClient: (
    apiClient: AuthConfidentialClient
  ) => Promise<AuthConfidentialClient>

  /**
   * Remove a Loaded company API clients
   */
  removeApiClient: (id: string) => Promise<void>

  /**
   * Add a third party connection
   */
  addThirdPartyConnection: (
    path: string,
    apiClient: ConnectionFormRequest
  ) => Promise<void>

  /**
   * Remove a third party connection
   */
  removeThirdPartyConnection: (path: string, id: string) => Promise<void>
}

const useCompanyStore = create<CompanyStore>((set) => ({
  company: {} as LoadedCompany,
  apiClients: [],
  availableScopes: [],
  getCompany: async (id) => {
    return AdminApi.get(`/companies/${id}`).then((company) =>
      set(() => ({ company }))
    )
  },
  getApiClients: async (id) => {
    return AdminApi.get(`/companies/${id}/api-clients`).then((apiClients) =>
      set(() => ({ apiClients }))
    )
  },
  getAvailableScopes: async () => {
    return AdminApi.get(`/api-clients/scopes`).then((availableScopes) =>
      set(() => ({ availableScopes }))
    )
  },
  addApiClient: (apiClient) => {
    return AdminApi.post(`/api-clients`, apiClient).then((apiClient) => {
      set((state) => ({ apiClients: [...state.apiClients, apiClient] }))
      return apiClient
    })
  },
  removeApiClient: (id) => {
    return AdminApi.remove(`/api-clients/${id}`).then(() =>
      set((state) => ({
        apiClients: state.apiClients.filter((item) => item.id !== id),
      }))
    )
  },
  addThirdPartyConnection: (path, apiClient) => {
    return AdminApi.post(`/third-party-connections/${path}`, apiClient).then(
      (apiClient) => {
        set((state) => ({ apiClients: [...state.apiClients, apiClient] }))
        return apiClient
      }
    )
  },
  removeThirdPartyConnection: (path, id) => {
    return AdminApi.remove(`/third-party-connections/${path}/${id}`).then(() =>
      set((state) => ({
        apiClients: state.apiClients.filter((item) => item.id !== id),
      }))
    )
  },
}))

export default useCompanyStore
