import Box from '@mui/material/Box'
import LinearProgress from '@mui/material/LinearProgress'

/**
 * Simple loading component that is intended for display in the main content
 * section of the app when waiting for route data to load.
 * @returns
 */
export default function FullPageLoader() {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
        textAlign: 'center',
        width: '100%',
        // 121px here is the sum of the the fixed Angular topbar height, bottom border, and bottom margin
        height: 'calc(100vh - 121px)',
      }}
    >
      <Box sx={{ width: '50%' }}>
        <LinearProgress />
      </Box>
    </Box>
  )
}
