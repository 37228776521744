// This is an extended copy of the same file in the Mercury repo
// TODO - pull this into a shared library

const TOKEN_STORAGE_KEY = 'ls.authorizationData'
const COMPANY_ID_STORAGE_KEY = 'ls.defaultCompany'

export class AuthError extends Error {
  constructor(message: string | undefined) {
    super(message)
  }
}

type OAuth2Response = {
  access_token: string
  token_type: string
  expires_in: number
}
export async function store(data: OAuth2Response) {
  window.localStorage.setItem(
    TOKEN_STORAGE_KEY,
    JSON.stringify({
      accessToken: data.access_token,
    })
  )
}

export async function signout() {
  window.localStorage.removeItem(TOKEN_STORAGE_KEY)
}

export async function isAuthenticated() {
  const tokenData = window.localStorage.getItem(TOKEN_STORAGE_KEY)
  if (tokenData && !isExpired(tokenData)) {
    return true
  }
  return false
}

export async function hasAdminRole() {
  const tokenData = window.localStorage.getItem(TOKEN_STORAGE_KEY)
  if (tokenData) {
    return getClaim(
      tokenData,
      'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
    ).includes('Admin')
  }
  return false
}

export function getAuthToken() {
  const rawAuthData = window.localStorage.getItem(TOKEN_STORAGE_KEY)
  if (rawAuthData) {
    const authData = JSON.parse(rawAuthData)
    if (!authData.accessToken) {
      throw new AuthError('unable to read stored token')
    }
    return authData.accessToken
  }
}

export function getCompanyId() {
  const rawCompanyId = window.localStorage.getItem(COMPANY_ID_STORAGE_KEY)
  if (rawCompanyId) {
    const companyId = JSON.parse(rawCompanyId)
    if (!companyId) {
      throw new AuthError('unable to read stored company id')
    }
    return companyId
  }
}

interface UserDetails {
  givenname: string
  surname: string
  email: string
}
export function getUserDetails(): UserDetails {
  const tokenData = window.localStorage.getItem(TOKEN_STORAGE_KEY)
  if (tokenData) {
    return {
      givenname: getClaim(
        tokenData,
        'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname'
      ),
      surname: getClaim(
        tokenData,
        'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname'
      ),
      email: getClaim(
        tokenData,
        'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress'
      ),
    }
  }
  return {
    givenname: '',
    surname: '',
    email: '',
  }
}

function isExpired(tokenData: string) {
  const expClaim = getClaim(tokenData, 'exp')
  const nowUnix = Math.floor(Date.now() / 1000)
  if (expClaim < nowUnix) {
    return true
  }
  return false
}

function getClaim(token: string, claimName: string) {
  const data = JSON.parse(token)
  const decodedToken = decodeJwt(data.accessToken)
  return decodedToken[claimName]
}

function decodeJwt(token: string) {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )

  return JSON.parse(jsonPayload)
}
