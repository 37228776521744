import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Box,
  Grid,
  Paper,
  Typography,
  TextField,
  FormControl,
  Stack,
  Avatar,
  Alert,
  Snackbar,
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'

import LogoSrc from '../../assets/logo-large.png'
import Copyright from '../../layout/components/Copyright'
import { store as storeAuthToken } from '../../utils/auth'
import appConfig from '../../utils/appConfig'

const SignIn = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value)
    setErrorMessage('')
  }

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value)
    setErrorMessage('')
  }

  const handleSubmit = async () => {
    setLoading(true)

    const payload = {
      grant_type: 'password',
      username: email,
      password,
      client_id: 'mercury',
    }

    const formBody = Object.keys(payload)
      .map(
        (key) =>
          encodeURIComponent(key) + '=' + encodeURIComponent(payload[key])
      )
      .join('&')

    fetch(`${await appConfig.get('authApiUrl')}/Token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: formBody,
    })
      .then(async (response) => {
        if (!response.ok && response.status === 400) {
          const responseBody = await response.text()
          if (responseBody.match(/Invalid username or password./)) {
            throw new Error(
              `Sorry, you've entered an invalid username or password. Please try again.`
            )
          }
          throw new Error(
            'An unexpected error occurred. Please try again later.'
          )
        }
        return response.json()
      })
      .then(async (data) => {
        await storeAuthToken(data)
        navigate('/')
      })
      .catch((error) => {
        setLoading(false)
        setErrorMessage(error.message)
      })
  }

  return (
    <>
      <Grid
        container
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        mt={10}
      >
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
          <img
            src={LogoSrc}
            alt="Logo"
            style={{ height: '80px', marginBottom: '32px' }}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sx={{ display: 'flex', justifyContent: 'center' }}
          mb={2}
        >
          <Typography component={'h1'} variant="h6">
            Sign in to get started
          </Typography>
        </Grid>

        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={1}>
          <Stack spacing={2} p={4}>
            <Box sx={{ display: 'flex', justifyContent: 'center' }} pb={2}>
              <Avatar
                sx={{
                  width: 60,
                  height: 60,
                  color: 'white',
                  backgroundColor: '#FF5C8E',
                }}
              >
                <LockOutlinedIcon />
              </Avatar>
            </Box>
            <FormControl>
              <TextField
                type="email"
                placeholder="Email"
                value={email}
                onChange={handleEmailChange}
                error={errorMessage !== ''}
              />
            </FormControl>
            <FormControl>
              <TextField
                type="password"
                placeholder="Password"
                value={password}
                onChange={handlePasswordChange}
                error={errorMessage !== ''}
              />
            </FormControl>
            <LoadingButton
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              loading={loading}
            >
              Go
            </LoadingButton>
          </Stack>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{ display: 'flex', justifyContent: 'center' }}
          mt={2}
        >
          <Copyright />
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={errorMessage !== ''}
        autoHideDuration={6000}
      >
        <Alert severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </>
  )
}

export default SignIn
