import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'

const Copyright = (props: any) => {
  return (
    <Typography
      variant="body2"
      color="rgba(0, 0, 0, 0.5)"
      align="center"
      {...props}
    >
      {'Copyright © '}
      <Link color="inherit" href="https://www.loadedhub.com/">
        Loaded
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}
export default Copyright
