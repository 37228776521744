import { Outlet, useMatches } from 'react-router-dom'

import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'

import Breadcrumb from './components/Breadcrumb'
import AppHeader from './AppHeader'
import AdminUserMenu from './components/AdminUserMenu'
import AppNavigation from './AppNavigation'
import { AppRouteDefinition } from 'src/routes/AppRoutes'

const drawerWidth = 300

/**
 * The main layout of the application, including the top bar, left hand menu,
 * and the main content area. Handles responsive layout and drawer open/close.
 * @returns
 */
export default function AppMainLayout() {
  // grab app specific data attached to the route configuration
  const routeData = useMatches() as AppRouteDefinition[]

  // We need to take the page title from the second item in the route data
  // because the first item is the root route (i.e. the one rendering this
  // component).
  const pageTitle = routeData[1]?.handle?.pageTitle

  return (
    <Box sx={{ display: 'flex' }}>
      <AppHeader position="absolute" drawerWidth={drawerWidth}>
        <Toolbar>
          <Box sx={{ flexGrow: 1 }}>
            <Breadcrumb />
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <AdminUserMenu />
          </Box>
        </Toolbar>
      </AppHeader>

      <AppNavigation drawerWidth={drawerWidth} />

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
          pt: 8, // to give space for the top bar
        }}
      >
        <Container maxWidth={false} sx={{ mt: 1, mb: 4 }}>
          <Typography
            component="h1"
            variant="h4"
            noWrap
            fontWeight={700}
            fontSize={'1.5rem'}
            sx={{ flexGrow: 1, mb: 2 }}
          >
            {pageTitle}
          </Typography>

          <Outlet />
        </Container>
      </Box>
    </Box>
  )
}
