import { NavLink, useMatches } from 'react-router-dom'
import { styled } from '@mui/material/styles'

const BreadcrumbNavLink = styled(NavLink)(({ theme }) => ({
  color: theme.palette.text.secondary,
  textDecoration: 'none',
  fontSize: '12px',
}))

const BreadcrumbSeparator = styled('span')(({ theme }) => ({
  color: theme.palette.text.secondary,
}))

/**
 * Renders each segment of the route in a breadcrumb style, each segment is a
 * link to the route.
 */
const Breadcrumb = () => {
  const routeData = useMatches()
  return (
    <>
      {routeData.reduce((all, routeSegment, idx) => {
        return [
          ...all,
          // add a divider if this isn't the first route segment
          idx !== 0 ? (
            <BreadcrumbSeparator key={idx + '-divider'}>
              {' '}
              /{' '}
            </BreadcrumbSeparator>
          ) : null,
          <BreadcrumbNavLink key={idx} to={routeSegment.pathname}>
            {routeSegment.handle?.pageTitle}
          </BreadcrumbNavLink>,
        ]
      }, [])}
    </>
  )
}
export default Breadcrumb
