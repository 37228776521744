const PREFIX = '@@loaded'

function setItem(key: string, val: string) {
  localStorage.setItem(`${PREFIX}/${key}`, val)
}

function getItem(key: string) {
  return localStorage.getItem(`${PREFIX}/${key}`)
}

function removeItem(key: string) {
  localStorage.removeItem(`${PREFIX}/${key}`)
}

function clear() {
  // We don't want to remove all localStorage keys, so we walk backward through
  // the keys and remove any with the @@loaded prefix
  for (let idx = localStorage.length - 1; idx >= 0; idx--) {
    const key = localStorage.key(idx) || ''
    if (key.match(/^@@loaded/)) {
      localStorage.removeItem(key)
    }
  }
}

/**
 * A Loaded prefixed wrapper around localStorage, all keys added / removed will
 * be prefixed with '@@loaded' to avoid collision with anyone elses keys.
 *
 * Note that calling clear() will _only_ clean up keys that have been added
 * through this API, it will not remove all localStorage keys.
 */
export default {
  setItem,
  getItem,
  removeItem,
  clear,
}
