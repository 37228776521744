import { lazy } from 'react'
import { defer } from 'react-router-dom'

import CompaniesLoader from './companies/loader'
import StockLoader from './stock/loader'
import UsersLoader from './users/loader'

const NullLoader = async () => defer({ data: null })

export default {
  CompanyList: {
    Component: lazy(() => import('./companies/Companies.Page')),
    Loader: CompaniesLoader.getAll,
  },
  CompanyShow: {
    Component: lazy(() => import('./companies/Companies[id].Page')),
    Loader: CompaniesLoader.get,
  },
  Dashboard: {
    Component: lazy(() => import('./dashboard/Dashboard.Page')),
    Loader: NullLoader,
  },
  Stock: {
    Component: lazy(() => import('./stock/Stock.Page')),
    Loader: StockLoader,
  },
  Users: {
    Component: lazy(() => import('./users/Users.Page')),
    Loader: UsersLoader,
  },
}
