import { createTheme } from '@mui/material/styles'

// A custom theme for this app
const theme = createTheme({
  typography: {
    fontFamily: ['Public Sans Variable', 'sans-serif'].join(','),
  },
  palette: {
    background: {
      default: 'rgb(249, 250, 251)',
    },
    primary: {
      //main: '#048395',
      main: 'rgb(92, 142, 255)',
    },
    secondary: {
      main: '#F7E5EE',
    },
    success: {
      main: '#30b237',
    },
    error: {
      main: '#FF5C8E',
    },
  },
  components: {
    MuiLink: {
      defaultProps: {
        underline: 'none',
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          borderBottom: 'none',
          backgroundColor: 'rgba(255, 255, 255, 0.5)',
          backdropFilter: 'blur(10px)',
        },
      },
    },
  },
})

export default theme
